const tab_sections = {
    overview: '概览',
    resources: '资源',
    users: '用户',
    access_control: '访问控制',
    help_and_support: '帮助与支持',
    tenant: '租户',
    automation: '自动化',
};
export default tab_sections;
