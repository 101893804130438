const contact = {
    title: '获取帮助',
    description: '有关帮助请求或产品反馈，请通过以下方式与我们联系。',
    discord: {
        title: '加入 Discord 社区',
        description: '与其他开发人员建立联系以找到解决方案',
        button: '加入',
    },
    github: {
        title: '在 GitHub 上交流',
        description: '在 GitHub 上创建问题并提交',
        button: '打开',
    },
    email: {
        title: '通过邮件联系支持',
        description: '发送电子邮件以获取更多信息和帮助',
        button: '发送',
    },
    reserve: {
        title: '与 Logto 团队预定时间',
        description: '快速预定一次在线聊天',
        button: '预定',
    },
};
export default contact;
