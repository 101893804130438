const general = {
    placeholder: 'Placeholder',
    skip: 'Pular',
    next: 'Próximo',
    back: 'Back',
    retry: 'Tente novamente',
    done: 'Feito',
    search: 'Buscar',
    search_placeholder: 'Buscar',
    clear_result: 'Limpar resultados',
    save: 'Salvar',
    save_changes: 'Salvar alterações',
    saved: 'Salvou',
    discard: 'Descartar',
    loading: 'Carregando...',
    redirecting: 'Redirecionando...',
    add: 'Adicionar',
    added: 'Adicionado',
    cancel: 'Cancelar',
    confirm: 'Confirme',
    check_out: 'Visualizar',
    create: 'Criar',
    set_up: 'Configurar',
    customize: 'Customizar',
    enable: 'Habilitar',
    reminder: 'Lembrete',
    delete: 'Excluir',
    more_options: 'MAIS OPÇÕES',
    close: 'Fechar',
    copy: 'Copiar',
    copying: 'Copiando',
    copied: 'Copiado',
    required: 'Obrigatório',
    add_another: 'Adicionar outro',
    deletion_confirmation: 'Tem certeza de que deseja excluir este {{title}}?',
    settings_nav: 'Configurações',
    unsaved_changes_warning: 'Você fez algumas alterações. Tem certeza de que deseja sair desta página?',
    leave_page: 'Deixar página',
    stay_on_page: 'Ficar na página',
    type_to_search: 'Digite para pesquisar',
    got_it: 'Entendi',
    continue: 'Continue',
    page_info: '{{min, number}}-{{max, number}} de {{total, number}}',
    learn_more: 'Saber mais',
    tab_errors: '{{count, number}} erros',
    skip_for_now: 'Skip for now',
    remove: 'Remove',
    visit: 'Visit',
    join: 'Join',
    try_now: 'Try Now',
    multiple_form_field: '(Multiple)',
    demo: 'Demo',
    unnamed: 'Sem nome',
    view: 'Visualizar',
    hide: 'Ocultar',
    unknown_error: 'Erro desconhecido, por favor tente novamente mais tarde.',
    select: 'Selecionar',
};
export default general;
