const contact = {
    title: 'Obter ajuda',
    description: 'Para solicitações de ajuda ou feedback do produto, entre em contato conosco pelos seguintes métodos.',
    discord: {
        title: 'Participe da comunidade Discord',
        description: 'Conecte-se com outros desenvolvedores para encontrar soluções',
        button: 'Participar',
    },
    github: {
        title: 'Comunique-se no GitHub',
        description: 'Crie um problema e envie-o no GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Entre em contato com o suporte via email',
        description: 'Envie um email para obter mais informações e ajuda',
        button: 'Enviar',
    },
    reserve: {
        title: 'Reserve seu horário com a equipe da Logto',
        description: 'Agende uma sessão para um bate-papo ao vivo',
        button: 'Agendar',
    },
};
export default contact;
