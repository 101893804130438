const contact = {
    title: "Obtenir de l'aide",
    description: "Pour les demandes d'aide ou les commentaires sur le produit, veuillez nous contacter par l'un des moyens suivants.",
    discord: {
        title: 'Rejoignez la communauté Discord',
        description: "Connectez-vous avec d'autres développeurs pour trouver des solutions",
        button: 'Rejoindre',
    },
    github: {
        title: 'Communiquez sur GitHub',
        description: 'Créez un problème et soumettez-le sur GitHub',
        button: 'Ouvrir',
    },
    email: {
        title: 'Contacter le support par e-mail',
        description: "Envoyez-nous un e-mail pour obtenir des informations complémentaires et de l'aide",
        button: 'Envoyer',
    },
    reserve: {
        title: "Réservez votre temps avec l'équipe Logto",
        description: 'Réservez rapidement une session pour un chat en direct',
        button: 'Réserver',
    },
};
export default contact;
