const contact = {
    title: 'Zdobądź pomoc',
    description: 'W przypadku żądań pomocy lub opinii na temat produktu, skontaktuj się z nami za pomocą następujących metod.',
    discord: {
        title: 'Dołącz do społeczności Discord',
        description: 'Połącz się z innymi deweloperami, aby znaleźć rozwiązania',
        button: 'Dołącz',
    },
    github: {
        title: 'Komunikuj się na GitHub',
        description: 'Utwórz zgłoszenie i opublikuj na GitHub',
        button: 'Otwórz',
    },
    email: {
        title: 'Skontaktuj się z pomocą techniczną przez e-mail',
        description: 'Wyślij do nas e-maila w celu uzyskania dalszych informacji i pomocy',
        button: 'Wyślij',
    },
    reserve: {
        title: 'Zarezerwuj swój czas z zespołem Logto',
        description: 'Szybko zarezerwuj sesję czatu na żywo',
        button: 'Rezerwuj',
    },
};
export default contact;
