const tab_sections = {
    overview: 'Genel Bakış',
    resources: 'Kaynaklar',
    users: 'Kullanıcılar',
    access_control: 'Erişim Kontrolü',
    help_and_support: 'Yardım ve Destek',
    tenant: 'Kiracı',
    automation: 'Otomasyon',
};
export default tab_sections;
