const tab_sections = {
    overview: 'Обзор',
    resources: 'Ресурсы',
    users: 'Пользователи',
    access_control: 'Управление доступом',
    help_and_support: 'Помощь и поддержка',
    tenant: 'Арендатор',
    automation: 'Автоматизация',
};
export default tab_sections;
