const tab_sections = {
    overview: 'Visão geral',
    resources: 'Recursos',
    users: 'Usuários',
    access_control: 'Controle de acesso',
    help_and_support: 'Ajuda e suporte',
    tenant: 'Locatário',
    automation: 'Automação',
};
export default tab_sections;
