const general = {
    placeholder: 'Marcador de posición',
    skip: 'Omitir',
    next: 'Siguiente',
    back: 'Atrás',
    retry: 'Intentar de Nuevo',
    done: 'Hecho',
    search: 'Buscar',
    search_placeholder: 'Buscar',
    clear_result: 'Borrar Resultados',
    save: 'Guardar',
    save_changes: 'Guardar Cambios',
    saved: 'Guardado',
    discard: 'Descartar',
    loading: 'Cargando...',
    redirecting: 'Redirigiendo...',
    add: 'Agregar',
    added: 'Agregado',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    check_out: 'Comprar',
    create: 'Crear',
    set_up: 'Configurar',
    customize: 'Personalizar',
    enable: 'Habilitar',
    reminder: 'Recordatorio',
    delete: 'Eliminar',
    more_options: 'MÁS OPCIONES',
    close: 'Cerrar',
    copy: 'Copiar',
    copying: 'Copiando',
    copied: 'Copiado',
    required: 'Requerido',
    add_another: 'Agregar Otro',
    deletion_confirmation: '¿Está seguro que desea eliminar este {{title}}?',
    settings_nav: 'Configuración',
    unsaved_changes_warning: 'Ha realizado algunos cambios. ¿Está seguro que desea salir de esta página?',
    leave_page: 'Salir de la Página',
    stay_on_page: 'Permanecer en la Página',
    type_to_search: 'Escriba para buscar',
    got_it: 'Entendido',
    continue: 'Continuar',
    page_info: '{{min, number}}-{{max, number}} de {{total, number}}',
    learn_more: 'Saber más',
    tab_errors: '{{count, number}} errores',
    skip_for_now: 'Omitir por ahora',
    remove: 'Eliminar',
    visit: 'Visitar',
    join: 'Unirse',
    try_now: 'Probar Ahora',
    multiple_form_field: '(Múltiple)',
    demo: 'Demostración',
    unnamed: 'Sin nombre',
    view: 'Ver',
    hide: 'Ocultar',
    unknown_error: 'Error desconocido, por favor inténtalo de nuevo más tarde.',
    select: 'Seleccionar',
};
export default general;
