const contact = {
    title: 'Yardım alın',
    description: 'Yardım istekleri veya ürün geribildirimleri için lütfen aşağıdaki yöntemlerle bizimle iletişime geçin.',
    discord: {
        title: 'Discord topluluğuna katıl',
        description: 'Diğer geliştiricilerle bağlantı kurup çözümler bulun',
        button: 'Katıl',
    },
    github: {
        title: 'GitHub üzerinden iletişim kurun',
        description: "Bir sorun oluşturun ve GitHub'a gönderin",
        button: 'Açık',
    },
    email: {
        title: 'E-posta ile destek iletişimi',
        description: 'Daha fazla bilgi ve yardım için bize e-posta gönderin',
        button: 'Send',
    },
    reserve: {
        title: 'Logto ekibiyle zaman ayırın',
        description: 'Hızlı bir görüşme için oturum ayırın',
        button: 'Programa al',
    },
};
export default contact;
