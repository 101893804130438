const tab_sections = {
    overview: '概觀',
    resources: '資源',
    users: '使用者',
    access_control: '訪問控制',
    help_and_support: '幫助與支援',
    tenant: '租戶',
    automation: '自動化',
};
export default tab_sections;
